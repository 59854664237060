<template>
  <div>
    <CSpinner v-if="$apollo.queries.vendor.loading" color="info"/>
    <CAlert
        v-if="error"
        color="danger"
        closeButton
        @update:show="removeError">
      {{error}}
    </CAlert>
    <DeleteModal
        entity="vendor"
        :name="itemName"
        :nid="vendor.nid"
        :show.sync="showDeleteItemModal"
        v-on:delete="deleteItem"
        v-on:cancel-delete="toggleDeleteItemModal"
    />
    <CNavbar
        type="light"
        class="navbar-partner"
    >
      <CNavItem
          :to="`/partners/vendor/${this.$route.params.vendor_nid}/sales-offices`"
          class="nav-item-partner"
      >
        Sales Offices
      </CNavItem>
    </CNavbar>
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol class="text-left" sm="2">
            <DetailsButtonGroup
                v-on:save="saveItem"
                v-on:cancel="resetItem"
                v-on:delete-prompt="toggleDeleteItemModal"
                v-on:close="$router.push({path: '/partners/vendors'})"
            />
          </CCol>
          <CCol sm="8">
            <h3 data-sel="txt-title">{{itemName}}</h3>
          </CCol>
          <CCol sm="2" class="align-content-end text-right">
            <CButton
                :href="wagtailUrl"
                target="_blank"
                rel="noreferrer noopener"
                color="info"
                variant="outline"
            >
              Edit in wagtail
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <VendorForm
            v-bind:vendorIn="vendorEdited"
            v-on:update-item="updateItem"
            v-on:error="catchError"
        />
      </CCardBody>
      <CCardFooter
          class="text-left">
        <DetailsButtonGroup
            v-on:save="saveItem"
            v-on:cancel="resetItem"
            v-on:delete-prompt="toggleDeleteItemModal"
            v-on:close="$router.push({ path: '/partners/vendors'})"
        />
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import gql from "graphql-tag";
import DetailsButtonGroup from "@/components/common/DetailsButtonGroup";
import DeleteModal from "@/components/common/DeleteModal";
import VendorForm from "@/apps/partners/components/VendorForm";
const clonedeep = require('lodash.clonedeep')

const queryVendor = gql`
query vendor($nid: ID!) {
  vendor(nid: $nid) {
    nid
    activePapp
    activeFh
    hidden
    shortName
    name
    operatesIn{
      nid
      name
      englishName
    }
    externalId
    isFranchiseHq
    sharePartnerOffices
    franchiseHq{
      nid
      shortName
    }
    #contact
    addressLine1
    addressLine2
    postalCode{
      nid
      postalCode
    }
    city
    country{
      nid
      name
    }
    contactEmail
    contactPhone
    anonymizationRequestEmails
    # Branding
    brandingPlan
    enableRegionalBranding
    description
    logo{
      nid
      name
      hrefOrig
    }
    officeProfilePicture{
      name
      hrefOrig
    }
    catalogueLink
    displayRegions{
      numericPostalCode
    }
    msDisplayRegions{
      numericPostalCode
    }
    imDisplayRegions{
      numericPostalCode
    }
    topLocalPlacement
    topLocalPlacementHouseList
    topVendorPlacement
    localPlacementRegionOverride{
      numericPostalCode
    }
    # Portfolio
    constructionMethods
    expansionStages
    filterPrice0To100k
    scorePrice0To100k
    filterPrice100kTo150k
    scorePrice100kTo150k
    filterPrice150kTo200k
    scorePrice150kTo200k
    filterPrice200kTo250k
    scorePrice200kTo250k
    filterPrice250kTo300k
    scorePrice250kTo300k
    filterPrice300kTo350k
    scorePrice300kTo350k
    filterPrice350kTo400k
    scorePrice350kTo400k
    filterPrice400kTo450k
    scorePrice400kTo450k
    filterPrice450kTo500k
    scorePrice450kTo500k
    filterPrice500kTo550k
    scorePrice500kTo550k
    filterPrice550kTo600k
    scorePrice550kTo600k
    filterPrice600kToInf
    scorePrice600kToInf
    filterSize0To75
    scoreSize0To75
    filterSize75To100
    scoreSize75To100
    filterSize100To120
    scoreSize100To120
    filterSize120To140
    scoreSize120To140
    filterSize140To160
    scoreSize140To160
    filterSize160To180
    scoreSize160To180
    filterSize180To200
    scoreSize180To200
    filterSize200To225
    scoreSize200To225
    filterSize225To250
    scoreSize225To250
    filterSize250To275
    scoreSize250To275
    filterSize275To300
    scoreSize275To300
    filterSize300ToInf
    scoreSize300ToInf
    scorePricePerSqm0To1000
    scorePricePerSqm1000To1200
    scorePricePerSqm1200To1400
    scorePricePerSqm1400To1600
    scorePricePerSqm1600To1800
    scorePricePerSqm1800To2000
    scorePricePerSqm2000To2200
    scorePricePerSqm2200To2400
    scorePricePerSqm2400To2600
    scorePricePerSqm2600To2800
    scorePricePerSqm2800To3000
    scorePricePerSqm3000ToInf
    filterUtilizationApartmentHouse
    filterUtilizationDetachedHouse
    filterUtilizationDuplex
    filterUtilizationTwoFamiliesHouse
    filterConstructionMethodBlockhouse
    filterConstructionMethodPrefab
    filterConstructionMethodSolid
    filterPropertyYes
    filterPropertyReserved
    filterPropertyExpected
    filterPropertyNo
    filterExpansionStagePriceReady
    filterExpansionStagePriceFitting
    filterExpansionStagePriceLego
    # Prequal and Filter
    activatePhonePrequalification
    handoverFilter
    # Handover
    notes
    handoverAssignmentPrimaryInput
    handoverAssignmentSecondaryInputFallback
    handoverRegions{
      numericPostalCode
    }
    msHandoverRegions{
      numericPostalCode
    }
    imHandoverRegions{
      numericPostalCode
    }
    sendDailyReporting
    dailyReportingFormat
    dailyReportingEmails
    sendInstantReporting
    instantReportingFormat
    instantReportingEmails
    instantReportingApi
    instantReportingApiEndpoint
    instantReportingApiExtraPayload
    instantReportingApiHeader
    instantReportingApiPreset
    instantReportingCsv
    # Handover to regions and sales agents
    activateHandoverToSalesAgent
    msActivateHandoverToSalesAgent
    disableSalesAgentApp
    salesAgentAppDisableEmailFeedbackButtons
    salesAgentAppDisableEmailSignupLink
    salesAgentAppDisableOpenOpportunityReminderEmail
    directHandoverTemplate
    msDirectHandoverTemplate
    factSheetId
    msFactSheetId
    activateNoContactDesiredToSalesAgents
    sendDailyReportingToSalesOffices
    # MS Handover
    msSendHtmlInstantHandover
    msHtmlInstantHandoverTemplate
    msHtmlInstantHandoverEmails
    msHtmlInstantHandoverEmailSubject
    msSendCsvHandover
    msCsvHandoverSchedule
    msCsvHandoverFormat
    msCsvHandoverEmails
    msCsvHandoverEmailSubject
    msCsvHandoverTemplate
    # Duplicate Reporting
    sendLeadDuplicateReporting
    leadDuplicateReportingEmails
    leadDuplicateReportingFormat
    # Reporting
    accountOwner {
      nid
      firstName
      lastName
    }
    monthlyVendorReportRecipients{
      nid
      email
    }
    imMonthlyVendorReportRecipients{
      nid
      email
    }
    leadFeedbackReportRecipients {
      nid
      email
    }
    # IM fields
    imProductId
    imCatalogueId
    imLandingPageId
    imSalesSegment
    imCatalogueImage{
      nid
      name
      hrefOrig
    }
    imPreselected
    # EX fields
    exclusiveLeadsClientNid
    exclusiveLeadsHandoverEmails
    exclusiveLeadsAttachmentFormat
    exclusiveLeadsSendDailyReporting
    exclusiveLeadsSendInstantReporting
  }
}
`
const updateVendor = gql`
  mutation updateVendor($nid: ID!, $vendor: VendorInput!){
    updateVendor(nid: $nid, vendor: $vendor){
        nid
    }
  }
`
const deleteVendor = gql`
  mutation deleteVendor($nid: ID!){
    deleteVendor(nid: $nid)
  }
`

export default {
  name: "Vendor",
  components: {
    DetailsButtonGroup,
    DeleteModal,
    VendorForm
  },
  data() {
    return {
      error: null,
      showDeleteItemModal: false,
      vendor: {},
      vendorEdited: {},
      vendorSave: {}
    }
  },
  methods: {
    removeError(hide){
      if(!hide){
        this.error = null
      }
    },
    catchError(event){
      this.error = event
    },
    updateItem(data){
      this.vendorSave = data
    },
    saveItem(){
      const {nid, __typename, ...updatedObj} = this.vendorSave;
      this.$apollo.mutate({
        mutation: updateVendor,
        variables: {
          nid: nid,
          vendor: updatedObj
        }
      })
      .then(res => {
        this.$emit('save-item', this.vendorEdited);
        this.$store.commit('set', ['addModalShow', false]);
        this.$store.commit('set', ['refetchData', true]);
        this.$router.push({ path: '/partners/vendors'});
      })
      .catch(error => {
        console.error(error);
        this.error = error.message;
      })
    },
    toggleDeleteItemModal(){
      this.showDeleteItemModal = !this.showDeleteItemModal;
    },
    resetItem(){
      this.vendorEdited = clonedeep(this.$apolloData.data.vendor);
    },
    deleteItem(){
      this.$apollo.mutate({
        mutation: deleteVendor,
        variables: {
          nid: this.vendor.nid
        }
      })
      .then(res => {
        this.$emit('delete-vendor', this.vendor.nid);
        this.$store.commit('set', ['refetchData', true]);
        this.$router.push({ path: '/partners/vendors'});
      })
      .catch(error => {
        console.error(error);
        this.error = error.message;
      })
    }
  },
  computed: {
    allowEdit() {
      return this.$store.state.allowEdit
    },
    itemName(){
      return this.vendorEdited.name
    },
    wagtailUrl(){
      return `https://www.fertighaus.de/admin/internal/vendor/?nid=${this.vendor.nid}`
    }
  },
  apollo: {
    vendor: {
      query: queryVendor,
      error(error) {
        this.error = error.message;
      },
      variables() {
        return {
          nid: this.$route.params.vendor_nid
        }
      },
      result ({ data, loading, networkStatus }) {
        this.resetItem();
      },
      fetchPolicy: "cache-and-network"
    }
  }
}
</script>

<style scoped>
  .navbar-partner{
    align-items: start;
    align-content: start;
    justify-content: start;
    padding-left: 0px;
    margin-left: 0px;
  }
  .nav-item-partner{
    list-style: none;
  }
</style>
